import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MaterialImportsModule } from "@sportyano/shared/ui-modules/material-imports";
import { PrimeModule } from "@sportyano/shared/ui-modules/primeng-imports";
import {
	PlayerCartComponent,
	CartReviewComponent,
	CartSkillsComponent,
	CartVideoComponent,
	HeadComponent,
	NavTabsComponent,
	RateCartComponent,
	SingleTrainingProgramComponent,
	ChangeMobileComponent,
	PaginationComponent,
	ShareModalComponent,
	ArabicOnlyDirective,
	EnglishOnlyDirective,
	AddPostPopUpComponent,
	AdvancedSideFilterationComponent,
	AdvertisementCardComponent,
	FilterationOptionsComponent,
	HeadAdvancedFilterationComponent,
	HeadSectionComponent,
	LocationCardComponent,
	MessagingDialogComponent,
	NewNewsCardComponent,
	NewsCardComponent,
	ProgramScheduleComponent,
	SportsPlaygroundViewComponent,
	SubscriptionComponent,
	UnlockProfileDialogComponent,
	ClickOutsideDirective,
	DynamicDialogComponent,
	LoginPopupComponent,
	CustomCalendarComponent,
} from "@sportyano/shared/components";
import { RecruitmentComponent } from "./components/recruitment/recruitment.component";
import { ImageCropperToolComponent } from "./components/image-cropper-tool/image-cropper-tool.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxSpinnerModule } from "ngx-spinner";
import { FileSizePipe } from "@sportyano/shared/pipes/file-size.pipe";
import { NumericOnlyDirective } from "@sportyano/shared/directives/numeric-only.directive";
import { CalendarSchedulerComponent } from "@sportyano/shared/components/calendar-scheduler/calender-scheduler.component";
import { CartComponent } from "@sportyano/shared/components/cart/cart.component";
import { TextLimiterDirective } from "@sportyano/shared/directives/text-limiter/text-limiter.directive";
import { StopClickPropagationDirective } from "@sportyano/shared/directives/stop-click-propagation/stop-click-propagation.directive";
import { CartSliderComponent } from "@sportyano/shared/components/cart-slider/cart-slider.component";
import { PreventCopyCutPasteDirective } from './directives/prevent-copy-cut-paste/prevent-copy-cut-paste.directive';
import { CheckLanguageDirective } from "@sportyano/shared/directives/check-language/check-language.directive";

const sharedComponents = [
	HeadComponent,
	CartVideoComponent,
	SingleTrainingProgramComponent,
	RateCartComponent,
	CartReviewComponent,
	CartSkillsComponent,
	SubscriptionComponent,
	NavTabsComponent,
	SubscriptionComponent,
	CartSkillsComponent,
	NewsCardComponent,
	MessagingDialogComponent,
	AddPostPopUpComponent,
	HeadSectionComponent,
	FilterationOptionsComponent,
	AdvancedSideFilterationComponent,
	HeadAdvancedFilterationComponent,
	NewNewsCardComponent,
	LocationCardComponent,
	ProgramScheduleComponent,
	SportsPlaygroundViewComponent,
	UnlockProfileDialogComponent,
	DynamicDialogComponent,
	ChangeMobileComponent,
	LoginPopupComponent,
	RecruitmentComponent,
	CustomCalendarComponent,
];

const sharedDirectives = [
	NumericOnlyDirective,
	EnglishOnlyDirective,
	ArabicOnlyDirective,
	ClickOutsideDirective,
	PreventCopyCutPasteDirective
];

@NgModule({
	declarations: [
		// components
		sharedComponents,

		// directives
		sharedDirectives,
		LoginPopupComponent,
		ImageCropperToolComponent
	],
	imports: [
		PrimeModule,
		MaterialImportsModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		ImageCropperModule,
		NgxSpinnerModule,
		FileSizePipe,
		PaginationComponent,
		ShareModalComponent,
		PlayerCartComponent,
		AdvertisementCardComponent,
		CalendarSchedulerComponent,
		CartComponent,
		TextLimiterDirective,
		StopClickPropagationDirective,
		CartSliderComponent,
		CheckLanguageDirective
	],
	exports: [
		// components
		sharedComponents,

		// directives
		sharedDirectives,

		// modules
		CommonModule,
		PrimeModule,
		MaterialImportsModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		NgxSpinnerModule,
		PaginationComponent,
		ShareModalComponent,
		PlayerCartComponent,
		AdvertisementCardComponent,
	],
})
export class SharedModule {}
